import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { ClerkProvider } from "@clerk/clerk-react";

import "@fontsource/audiowide";
import "@fontsource/montserrat";
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss";
import "assets/demo/demo.css";

import LandingPage from "views/LandingPage.js";
import JobsPage from "views/JobsPage";
import { API_URL } from "variables/api";
import { SESSION_KEY } from "variables/clerk";

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

if (!API_URL) {
  throw new Error("Missing API URL");
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
const root = ReactDOM.createRoot(document.getElementById("root"));

const AnimatedRoutes = () => {
  const location = useLocation();
  console.log("🚀 ~ file: index.js:38 ~ AnimatedRoutes ~ location:", location)
  const navigate = useNavigate();
  console.log("🚀 ~ file: index.js:40 ~ AnimatedRoutes ~ navigate:", navigate)

  useEffect(() => {
    if (window.location.pathname === location.pathname) {
      window.scrollTo(0, 0);
    }

    if (sessionStorage.getItem(SESSION_KEY)) {
      sessionStorage.removeItem(SESSION_KEY);
      if (location.pathname !== "/jobs") {
        console.log("🚀 ~ file: index.js:50 ~ useEffect ~ location.pathname:", location.pathname)
        navigate("/jobs");
      }
    }
  }, [location.pathname, navigate]);

  return (
    <ClerkProvider publishableKey={clerkPubKey} navigate={(to) => navigate(to)}>
      <TransitionGroup>
        <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
          <Routes location={location}>
            <Route path="/" element={<LandingPage />} />
            <Route path="/jobs" element={<JobsPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </ClerkProvider>
  );
};

const App = () => {
  return (
    <BrowserRouter basename="/">
      <AnimatedRoutes />
    </BrowserRouter>
  );
};

root.render(<App />);
