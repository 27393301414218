import { Fallback } from "components/Fallback/Fallback";
import { lazy, Suspense, useEffect } from "react";
import { Container } from "reactstrap";

const iconCards = [
  {
    id: "lorem",
    icon: "fas fa-briefcase",
    title: "Commitment to excellence",
    description:
      "Driven by our unwavering commitment to excellence, we go the extra mile to ensure our clients are completely satisfied with our work.",
  },
  {
    id: "paper",
    icon: "fas fa-paper-plane",
    title: "Large-scale projects",
    description:
      "Our engineers have long experience in the Middle East, particularly on large-scale projects. Our skilled workers allows us to source whatever is needed.",
  },
  {
    id: "code",
    icon: "fas fa-database",
    title: "Skilled workforce",
    description:
      "Our skilled workforce spans a wide range of trades, including masons, precast erectors, welders, drywall installers, spray plaster applicators, and carpenters.",
  },
];

const MainNavbar = lazy(() => import("components/Navbars/MainNavbar.js"));
const Header = lazy(() => import("components/Header/Header.js"));
const IconCard = lazy(() => import("components/IconCard/IconCard.js"));
const AboutUs = lazy(() => import("components/AboutUs/AboutUs.js"));
const CTA = lazy(() => import("components/CTA/CTA.js"));
const Footer = lazy(() => import("components/Footer/Footer.js"));

export default function LandingPage() {
  useEffect(() => {
    document.body.classList.toggle("landing-page");

    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };
  }, []);

  return (
    <div>
      <Suspense fallback={<Fallback name="navbar" />}>
        <MainNavbar />
      </Suspense>

      <div className="wrapper">
        <div className="page-header">
          <img
            alt="..."
            className="path"
            src={require("assets/img/blob.png")}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path2.png")}
          />
          <img
            alt="..."
            className="shapes triangle"
            src={require("assets/img/triunghiuri.png")}
          />
          <img
            alt="..."
            className="shapes wave"
            src={require("assets/img/waves.png")}
          />
          <img
            alt="..."
            className="shapes squares"
            src={require("assets/img/patrat.png")}
          />
          <img
            alt="..."
            className="shapes circle"
            src={require("assets/img/cercuri.png")}
          />
          <Suspense fallback={<Fallback name="header" />}>
            <Header />
          </Suspense>
          <Container>
            <Suspense fallback={<Fallback name="cards" />}>
              <section id="iconCards" className="section">
                {iconCards.map((card) => (
                  <IconCard key={card.id} {...card} />
                ))}
              </section>
            </Suspense>
            <Suspense fallback={<Fallback name="about us" />}>
              <AboutUs />
            </Suspense>
            <Suspense fallback={<Fallback name="CTA" />}>
              <CTA />
            </Suspense>
          </Container>
          <Suspense fallback={<Fallback name="footer" />}>
            <Footer />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
