import { Fallback } from "components/Fallback/Fallback";
import { lazy, Suspense, useEffect } from "react";
import { Container } from "reactstrap";

const MainNavbar = lazy(() => import("components/Navbars/MainNavbar.js"));
const Form = lazy(() => import("components/Form/Form.js"));
const Footer = lazy(() => import("components/Footer/Footer.js"));

export default function JobsPage() {
  useEffect(() => {
    document.body.classList.toggle("jobs-page");

    return function cleanup() {
      document.body.classList.toggle("jobs-page");
    };
  }, []);

  return (
    <div>
      <Suspense fallback={<Fallback name="navbar" />}>
        <MainNavbar />
      </Suspense>

      <div className="wrapper">
        <div className="page-header">
          <img
            alt="..."
            className="path"
            src={require("assets/img/blob.png")}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path2.png")}
          />
          <img
            alt="..."
            className="shapes triangle"
            src={require("assets/img/triunghiuri.png")}
          />
          <img
            alt="..."
            className="shapes wave"
            src={require("assets/img/waves.png")}
          />
          <img
            alt="..."
            className="shapes squares"
            src={require("assets/img/patrat.png")}
          />
          <img
            alt="..."
            className="shapes circle"
            src={require("assets/img/cercuri.png")}
          />
          <Container>
            <Suspense fallback={<Fallback name="form" />}>
              <Form />
            </Suspense>
          </Container>
        </div>
      </div>

      <h1>Job Application</h1>

      <Suspense fallback={<Fallback name="footer" />}>
        <Footer />
      </Suspense>
    </div>
  );
}
